(() => {
  const v3Rankings = document.querySelectorAll('.v3-rankings');
  const schools = document.querySelectorAll('.school');

  if (!v3Rankings) {
    return;
  }

  // function: Show All Schools
  v3Rankings.forEach((ranking) => {
    const showAllButton = ranking.querySelector('.view-more-js');
    const viewMore = ranking.querySelector('.view-more-button');
    if (showAllButton && viewMore) {
      showAllButton.addEventListener('click', (e) => {
        e.preventDefault();
        viewMore.classList.add('hidden');
        ranking.classList.add('v3-rankings__show-all');
      });
    }
  });
  schools.forEach((school) => {
    // Tagular - ElementViewed - Organic School Card
    const position = school.dataset.position || null;
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            window.tagular('beam', 'ElementViewed', {
              '@type': 'redventures.usertracking.v3.ElementViewed',
              webElement: {
                elementType: 'ranking-card',
                htmlId: school.id,
                location: 'rankings-list',
                name: 'organic-card',
                position,
                text: school.dataset.title || '',
              },
            });
            observer.disconnect();
          }
        },
        {
          threshold: 0.5,
        }
      );
      observer.observe(school);
    }

    const toggleButton = school.querySelector('.button-toggle-school');
    if (toggleButton) {
      toggleButton.addEventListener('click', (e) => {
        e.preventDefault();
        const action = school.classList.contains('is-active')
          ? 'collapse'
          : 'expand';

        // Tagular - ElementClicked - More Details Toggle
        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: 'ranking-card',
            htmlId: toggleButton.dataset.id,
            location: 'rankings-list',
            name: 'organic-card',
            position: toggleButton.dataset.position,
            text: toggleButton.innerText,
          },
          actionOutcome: action.toUpperCase(),
        });

        // event: Expand Blurbs
        school.classList.toggle('is-active');

        const toggleIcon = toggleButton.querySelectorAll('sonic-icon');
        if (school.classList.contains('is-active')) {
          toggleButton.innerHTML = 'Less Details';
          toggleIcon.forEach((icon) => {
            icon.setAttribute('icon', 'chevron-up');
            toggleButton.prepend(icon);
          });
        } else {
          toggleButton.innerHTML = 'More Details';
          toggleIcon.forEach((icon) => {
            icon.setAttribute('icon', 'chevron-down');
            toggleButton.prepend(icon);
          });
        }
      });
    }

    // Tagular - ElementClicked - School Profile Link - Title
    const sppTitleLink = school.querySelector('.spp-title-link');
    if (sppTitleLink) {
      sppTitleLink.addEventListener('click', () => {
        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: 'ranking-card',
            htmlId: sppTitleLink.dataset.id,
            location: 'rankings-list',
            position: sppTitleLink.dataset.position,
            name: 'school_profile_entry',
            text: sppTitleLink.innerText,
          },
          outboundUrl: sppTitleLink.href,
          actionOutcome: 'INTERNALLINK',
        });
      });
    }

    // Tagular - ElementClicked - School Profile Link - CTA
    const sppLink = school.querySelector('.button-view-school');
    if (sppLink) {
      sppLink.addEventListener('click', () => {
        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: 'ranking-card',
            htmlId: sppLink.dataset.id,
            location: 'rankings-list',
            position: sppLink.dataset.position,
            name: 'school_profile_entry',
            text: sppLink.innerText,
          },
          outboundUrl: sppLink.href,
          actionOutcome: 'INTERNALLINK',
        });
      });
    }
    // Tagular - ElementClicked - ROI Tooltip
    const roiTooltip = school.querySelector('.roi-tooltip-js');
    if (roiTooltip) {
      roiTooltip.addEventListener('click', () => {
        window.tagular('beam', 'ElementClicked', {
          '@type': 'redventures.usertracking.v3.ElementClicked',
          webElement: {
            elementType: 'ranking-card',
            htmlId: roiTooltip.id,
            location: 'rankings-list',
            position: school.dataset.position,
            name: 'roi-score',
            text: 'ROI Score',
          },
          actionOutcome: 'EXPAND',
        });
      });
    }
  });
})();
